<template>
  <b-row class="text-center overflow-hidden">
    <b-col
      cols="12"
      class="hp-error-content py-32"
    >
      <b-row
        align-h="center"
        align-v="center"
        class="h-100"
      >
        <b-col>
          <h1
            class="hp-error-content-title font-weight-light hp-text-color-black-bg hp-text-color-dark-0 mb-0"
          >
            403
          </h1>

          <h2 class="h1 mb-16">
            Mohon Maaf, Anda Tidak Memiliki Akses ke Halaman Ini
          </h2>

          <p
            class="mb-32 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0"
          >
            You can go back home.
          </p>

          <b-button
            variant="primary"
            @click="goingBack"
          >
            <span>Back to Previous Page</span>
          </b-button>
        </b-col>
      </b-row>
    </b-col>

    <footer-item />
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  methods: {
    goingBack() {
      this.$router.go(-1)
    },
  },
}
</script>
